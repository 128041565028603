/* global document */

/**
 * IMPORTS
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { Button, Form as ReactForm } from 'reactstrap';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import { isEmail } from 'validator';
import classNames from 'classnames';
import { Translate } from 'react-i18nify';

import Keyboard from '../keyboard/Keyboard';
import CustomLayout from '../keyboard/layouts/CustomLayout';
import TimeoutButton from '../TimeoutButton';
import {
  getProjectForm,
} from '../../actions/project-actions';
import {
  addSessionData,
} from '../../actions/session-actions';
import {
  completeStep,
  cancelStep,
} from '../../actions/nav-actions';
import {
  getCurrentProjectId,
  getStepDisabled,
  getCurrentFormEmailFields,
  getFormInitialValues,
} from '../../selectors';

/**
 * CORE
 */

function createInput() {
  const element = document.createElement('input');
  element.type = 'text';
  return element;
}

const TICKS_BEFORE_CANCEL = 60;

class Email extends Component {
  static stepId = 'email';

  static propTypes = {
    disabled: PropTypes.bool,
    field: PropTypes.object,
    emailValue: PropTypes.string,
    emailValid: PropTypes.bool,
    entered: PropTypes.bool.isRequired,
    projectId: PropTypes.string.isRequired,
    getProjectForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    completeStep: PropTypes.func.isRequired,
    cancelStep: PropTypes.func.isRequired,
    addSessionData: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired, // redux-form explicit change
  };

  static defaultProps = {
    disabled: false,
    field: { name: 'email', multiple: false },
    emailValue: null,
    emailValid: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      ticksBeforeCancel: TICKS_BEFORE_CANCEL,
    };

    this.onInput = this.onInput.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentWillMount() {
    const { projectId } = this.props;
    this.inputNode = createInput();
    this.inputNode.addEventListener('input', this.onInput);
    this.props.getProjectForm(projectId);
  }

  componentDidMount() {
    const { emailValue } = this.props;
    this.inputNode.value = emailValue;
  }

  componentWillUnmount() {
    this.inputNode.removeEventListener('input', this.onInput);
  }

  onSubmit(data) {
    const { field: { name, multiple } } = this.props;
    const value = data[name];
    this.props.addSessionData({ [name]: multiple ? [value] : value });
    this.props.completeStep(Email.stepId);
  }

  onCancel(event) {
    if (event) {
      event.preventDefault();
    }

    this.props.cancelStep(Email.stepId);
  }

  onInput({ target: { value } }) {
    const newValue = value
      .replace(/(\.|\.c|\.co|\.com)\.com$/, '.com')
      .replace(/\.+/, '.')
      .replace(/@+/, '@')
      .replace(/@\.(com)?/, '@');
    this.setInputValue(newValue);
  }

  onReset() {
    this.setInputValue('');
  }

  setInputValue(value) {
    const { field: { name } } = this.props;
    if (this.inputNode.value !== value) {
      this.inputNode.value = value;
    }
    this.props.change(name, value);
    this.setState({
      ticksBeforeCancel: TICKS_BEFORE_CANCEL,
    });
  }

  renderInput() {
    const { emailValue, emailValid } = this.props;

    return (
      <h1 style={{ fontSize: '5rem' }}>
        {emailValue}
        {!emailValid && (
          <span
            className="text-danger ml-2"
            onClick={this.onReset}
          >
            <FA
              icon={faTimesCircle}
              fixedWidth
            />
          </span>
        )}
      </h1>
    );
  }

  render() {
    const {
      disabled,
      entered,
      field,
      handleSubmit,
      emailValue,
      emailValid,
    } = this.props;
    const { ticksBeforeCancel } = this.state;

    return (
      <Fragment>
        <ReactForm
          onSubmit={handleSubmit(this.onSubmit)}
          noValidate
          className="form-wrapper"
        >
          <div
            className={classNames('form-group', { focused: !!emailValue })}
          >
            <h1 className="form-label">
              <Translate value={`Form.${field.name}.placeholder`} />
            </h1>
            <h1 className="form-input">
              {emailValue
                ? <span>{emailValue}</span>
                : <span>&nbsp;</span>
              }
            </h1>
          </div>
          <div>
            <TimeoutButton
              buttonProps={{
                className: 'mr-2 app-button',
                color: 'secondary',
                outline: true,
                size: 'lg',
                onClick: this.onCancel,
              }}
              ticks={ticksBeforeCancel}
              onTimeout={this.onCancel}
              disabled={disabled}
            >
              <FA icon={faTimes} />
              &nbsp;
              <Translate value="Form.cancel" />
            </TimeoutButton>
            {emailValid && (
              <Button
                className="app-button button-blink"
                color="primary"
                size="lg"
              >
                <FA icon={faCheck} />
                &nbsp;
                <Translate value="Form.submit" />
              </Button>
            )}
          </div>
        </ReactForm>
        {entered && (
          <Keyboard
            inputNode={this.inputNode}
            layouts={[CustomLayout]}
            className={!emailValue && 'keyboard-blink'}
          />
        )}
      </Fragment>
    );
  }
}

export default compose(
  connect(
    (state) => {
      const emailFields = getCurrentFormEmailFields(state);
      const field = emailFields
        .map(({ name, multiple }) => ({ name, multiple }))
        .shift(); // object or undefined

      if (field) {
        const initialValues = getFormInitialValues(state);
        const { name } = field;
        [initialValues[name]] = [].concat(initialValues[name]); // as single value (if array)
        const selector = formValueSelector('data-collect-email');
        const [email] = [].concat(selector(state, name)); // as single value (if array)
        const emailValue = email || initialValues[name];

        return {
          disabled: getStepDisabled(state),
          projectId: getCurrentProjectId(state),
          field,
          emailValue,
          emailValid: isEmail(emailValue || initialValues[name] || ''),
          initialValues,
        };
      }

      return {
        disabled: getStepDisabled(state),
        projectId: getCurrentProjectId(state),
        field,
      };
    },
    {
      getProjectForm,
      completeStep,
      cancelStep,
      addSessionData,
    },
  ),
  reduxForm({
    form: 'data-collect-email',
  }),
)(Email);
