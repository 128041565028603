/* global window */

/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import ReactInterval from 'react-interval-timer';
import { Navbar, NavbarBrand } from 'reactstrap';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { I18n } from 'react-i18nify';

import SoundPlayer from './components/SoundPlayer';
import AsyncError from './components/AsyncError';
import {
  Home,
  Trigger,
  Selfie,
  Select,
  Scan,
  Form,
  Preview,
  End,
  Email,
  Checkboxes,
  NotReady,
  Message,
  ProjectPicker,
} from './components/steps';

import {
  checkOnline,
} from './actions/app-actions';
import {
  getProject,
  getProjectStatus,
} from './actions/project-actions';
import {
  getLocation as fetchLocation,
  getWeather as fetchWeather,
} from './actions/third-party-actions';
import {
  getStep,
  getLocation,
  getCurrentProjectId,
  getCurrentProjectStatus,
  getRequestingStatus,
  getCurrentProjectInitializedStatus,
  getWeatherIsEnabled,
  getOnlineStatus,
  isOnlineRequested,
} from './selectors';

import logo from './assets/logo_nav.png';
import ActionQueue from './components/ActionQueue';

/**
 * CONSTANTS
 */

const {
  REACT_APP_VERSION: VERSION,
  NODE_ENV,
} = process.env;

/* eslint-disable-next-line no-alert */
const onShowVersion = () => window.alert(`Version ${VERSION}`);

const getStepComponent = (stepId) => {
  const steps = {
    home: Home,
    trigger: Trigger,
    selfie: Selfie,
    select: Select,
    scan: Scan,
    form: Form,
    preview: Preview,
    end: End,
    email: Email,
    checkboxes: Checkboxes,
    'not-ready': NotReady,
    'project-picker': ProjectPicker,
  };
  const StepComp = steps[stepId];
  return <StepComp key={stepId} />;
};

/**
 * APP NAVBAR
 */

const AppNavbar = ({ showSpinner }) => (
  <Navbar className="sticky-top app-nav">
    <NavbarBrand onClick={onShowVersion}>
      <img src={logo} alt="logo" />
    </NavbarBrand>
    <div className="banner">
      <div>
        {showSpinner && (
          <FA
            id="request-spinner"
            icon={faSpinner}
            pulse
            fixedWidth
          />
        )}
      </div>
    </div>
  </Navbar>
);

AppNavbar.propTypes = {
  showSpinner: PropTypes.bool,
};

AppNavbar.defaultProps = {
  showSpinner: false,
};

/**
 * CORE
 */

class App extends Component {
  static propTypes = {
    step: PropTypes.string,
    location: PropTypes.string,
    projectId: PropTypes.string,
    projectStatus: PropTypes.object,
    isInitialized: PropTypes.bool,
    isFetching: PropTypes.bool,
    isWeatherEnabled: PropTypes.bool,
    online: PropTypes.bool,
    isFetchingOnline: PropTypes.bool,

    getProject: PropTypes.func.isRequired,
    getProjectStatus: PropTypes.func.isRequired,
    fetchLocation: PropTypes.func.isRequired,
    fetchWeather: PropTypes.func.isRequired,
    checkOnline: PropTypes.func.isRequired,
  }

  static defaultProps = {
    step: undefined,
    location: undefined,
    projectId: undefined,
    projectStatus: { isOK: true },
    isInitialized: false,
    isFetching: false,
    isWeatherEnabled: false,
    online: true,
    isFetchingOnline: false,
  }

  constructor(props) {
    super(props);

    this.refreshProject = this.refreshProject.bind(this);
    this.fetchWeather = this.fetchWeather.bind(this);
    this.onOnlineInterval = this.onOnlineInterval.bind(this);
  }

  componentDidMount() {
    I18n.setLocale(process.env.REACT_APP_LANGUAGES.split(',')[0]);
    if (NODE_ENV !== 'production') {
      I18n.setHandleMissingTranslation(key => `Missing: ${key}`);
    }
    this.fetchWeather();
  }

  componentWillReceiveProps({ location }) {
    if (location !== this.props.location) {
      this.fetchWeather(location);
    }
  }

  onOnlineInterval() {
    const { isFetchingOnline } = this.props;
    if (!isFetchingOnline) {
      this.props.checkOnline(3000);
    }
  }

  fetchWeather() {
    const { isWeatherEnabled } = this.props;
    if (!isWeatherEnabled) return;

    const { location } = this.props;
    if (location) {
      this.props.fetchWeather(location);
    } else {
      this.props.fetchLocation();
    }
  }

  refreshProject() {
    const { isInitialized } = this.props;
    if (!isInitialized) {
      return;
    }

    const { projectId, projectStatus } = this.props;
    if (projectStatus.isOK) {
      this.props.getProject(projectId);
    }
    this.props.getProjectStatus(projectId);
  }

  render() {
    const {
      isFetching,
      projectStatus,
      step,
      isWeatherEnabled,
      online,
    } = this.props;

    // const withNav = step !== 'selfie' && step !== 'home';
    const withNav = step !== 'selfie';

    return (
      <div className="app">
        {/* Component to perform/see queued action */}
        <ActionQueue />

        {/* Invisible component to play sounds */}
        <SoundPlayer />

        {/* Modal showing async errors + retry/cancel */}
        <AsyncError />

        {/* Main Content */}
        <div className={withNav ? 'with-nav' : 'without-nav'}>
          {withNav && <AppNavbar showSpinner={isFetching} />}

          <TransitionGroup>
            {getStepComponent(step)}
          </TransitionGroup>
        </div>

        <ReactInterval
          timeout={online ? 20000 : 4000}
          enabled
          callback={this.onOnlineInterval}
        />
        <ReactInterval
          timeout={projectStatus.isOK ? 30000 : 3000}
          enabled={online}
          callback={this.refreshProject}
        />
        <ReactInterval
          timeout={30 * 60 * 1000}
          enabled={online && isWeatherEnabled}
          callback={this.fetchWeather}
        />

        {/* If offline */}
        {!online && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
              zIndex: 4000,
            }}
          >
            <Message
              text={'Internet is not available.\nPlease check network settings.'}
            />
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    step: getStep(state),
    location: getLocation(state),
    projectId: getCurrentProjectId(state),
    projectStatus: getCurrentProjectStatus(state),
    isInitialized: getCurrentProjectInitializedStatus(state),
    isFetching: getRequestingStatus(state),
    isWeatherEnabled: getWeatherIsEnabled(state),
    online: getOnlineStatus(state),
    isFetchingOnline: isOnlineRequested(state),
  }),
  {
    getProject,
    getProjectStatus,
    fetchLocation,
    fetchWeather,
    checkOnline,
  },
)(App);
