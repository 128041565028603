/**
 * IMPORTS
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * STYLES
 */

const containerStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
  zIndex: -1,
};

const videoStyle = {
  position: 'absolute',
  minWidth: '100%',
  minHeight: '100%',
  width: 'auto',
  height: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const tintStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

/**
 * CORE
 */

const VideoBackground = ({ src, tint }) => (
  <div
    style={containerStyle}
  >
    <video
      controls={false}
      muted
      autoPlay
      loop
      style={videoStyle}
    >
      <source src={src} type="video/mp4" />
    </video>
    {tint && (
      <div style={Object.assign({ backgroundColor: tint }, tintStyle)} />
    )}
  </div>
);

VideoBackground.propTypes = {
  src: PropTypes.string.isRequired,
  tint: PropTypes.string,
};

VideoBackground.defaultProps = {
  tint: undefined,
};

export default VideoBackground;
