/**
 * IMPORTS
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import ReactInterval from 'react-interval-timer';
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Badge,
  Spinner,
} from 'reactstrap';

import { completeStep } from '../../actions/nav-actions';
import { initializeClient } from '../../actions/app-actions';
import {
  pickProject,
  initializeProject,
  getProjectStatus,
} from '../../actions/project-actions';
import {
  getStepDisabled,
  getAllClients,
  getAllProjects,
} from '../../selectors';

/**
 * CONSTANTS
 */

const {
  REACT_APP_APP_ID: APP_ID,
} = process.env;

const APP_IDS = APP_ID.split(',');

/**
 * UTILS
 */

const hasClientFailed = client => client && !(client.initializing || client.initialized);
const hasProjectFailed = project => project && !(project.initializing || project.initialized);

/**
 * CLIENT CONTENT
 */

const getColor = ({ isOK, projectIsEnabled }) => {
  if (isOK) return 'success';
  return projectIsEnabled ? 'danger' : 'warning';
};

const getLabel = ({ isOK, projectIsEnabled, deviceIsOnline, disabledMessage }) => {
  if (isOK) return 'Ready';
  if (!deviceIsOnline) return 'Offline';
  if (!projectIsEnabled) return disabledMessage || 'Disabled';
  return 'Error';
};

const ClientContent = ({ client, project }) => {
  const success = project && project.initialized && project.status;

  if (success) {
    return (
      <Fragment>
        {project.name}
        <Badge
          color={getColor(project.status)}
          pill
        >
          {getLabel(project.status)}
        </Badge>
      </Fragment>
    );
  }

  if (hasClientFailed(client) || hasProjectFailed(project)) {
    return (
      <Fragment>
        {`Failed to initialize id "${client.appId}"`}
        <Badge
          color="danger"
          pill
        >
          Failed
        </Badge>
      </Fragment>
    );
  }

  return (
    <Fragment>
      Loading...
      <Spinner size="sm" />
    </Fragment>
  );
};

ClientContent.propTypes = {
  client: PropTypes.object,
  project: PropTypes.object,
};

ClientContent.defaultProps = {
  client: undefined,
  project: undefined,
};

/**
 * CORE
 */

class ProjectPicker extends Component {
  static stepId = 'project-picker';

  static propTypes = {
    disabled: PropTypes.bool,
    clients: PropTypes.array,
    projects: PropTypes.array,
    completeStep: PropTypes.func.isRequired,
    pickProject: PropTypes.func.isRequired,
    initializeClient: PropTypes.func.isRequired,
    initializeProject: PropTypes.func.isRequired,
    getProjectStatus: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    clients: [],
    projects: [],
  };

  constructor(...args) {
    super(...args);
    this.state = {
      hasSelected: false,
    };
    this.refreshStatuses = this.refreshStatuses.bind(this);
  }

  componentDidMount() {
    APP_IDS.forEach(this.props.initializeClient);
  }

  componentWillReceiveProps({ clients, projects }) {
    if (APP_IDS.length === 1) {
      const [client] = clients;
      const [project] = projects;
      if (_.get(client, 'initialized', false) && _.get(project, 'initialized', false)) {
        this.onSelect(client);
      }
    }
  }

  onSelect(client) {
    const { hasSelected } = this.state;
    if (!hasSelected) {
      this.setState(
        { hasSelected: true },
        () => {
          this.props.pickProject(client.projectId);
          this.props.completeStep(ProjectPicker.stepId);
        },
      );
    }
  }

  refreshStatuses() {
    const { clients, projects } = this.props;
    // refresh successful projects
    projects
      .filter(({ initialized }) => initialized)
      .map(({ id }) => this.props.getProjectStatus(id));
    // refresh failed clients
    clients
      .filter(hasClientFailed)
      .map(({ appId }) => this.props.initializeClient(appId));
    // refresh failed projects
    projects
      .filter(hasProjectFailed)
      .map(({ id }) => this.props.initializeProject(id));
  }

  renderSingleClient() {
    const {
      clients,
      projects,
    } = this.props;

    const [client] = clients;
    const [project] = projects;
    return (
      <Fragment>
        <ReactInterval
          timeout={5000}
          callback={this.refreshStatuses}
          enabled
        />
        <ClientContent
          client={client}
          project={project}
        />
      </Fragment>
    );
  }

  render() {
    const {
      disabled,
      clients,
      projects,
    } = this.props;

    if (APP_IDS.length === 1) {
      return this.renderSingleClient();
    }

    const items = clients.map((client) => {
      const project = projects.find(({ id }) => id === client.projectId);
      const isProjectOK = !!project
        && project.initialized
        && project.status
        && project.status.isOK;
      return (
        <ListGroupItem
          key={client.appId}
          tab="button"
          onClick={() => this.onSelect(client)}
          disabled={disabled || !isProjectOK}
          className="d-flex justify-content-between align-items-center"
          action
        >
          <ClientContent
            client={client}
            project={project}
          />
        </ListGroupItem>
      );
    });

    return (
      <Container>
        <ReactInterval
          timeout={5000}
          callback={this.refreshStatuses}
          enabled
        />
        <Card>
          <CardHeader tag="h2">
            Pick your project
          </CardHeader>
          <CardBody>
            <ListGroup>
              {items}
            </ListGroup>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default connect(
  state => ({
    disabled: getStepDisabled(state),
    clients: getAllClients(state),
    projects: getAllProjects(state),
  }),
  {
    completeStep,
    pickProject,
    initializeClient,
    initializeProject,
    getProjectStatus,
  },
)(ProjectPicker);
