export default () => {
  const languages = process.env.REACT_APP_LANGUAGES.split(',');
  const loadLangs = languages.map((
    lang => import(`../assets/texts/${lang}.json`)
      .then(texts => ({ [lang]: texts }))
  ));

  return Promise.all(loadLangs)
    .then(results => results.reduce((acc, l) => Object.assign(acc, l), {}));
};
