/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactInterval from 'react-interval-timer';
import {
  Button,
} from 'reactstrap';
import { transparentize } from 'polished';

import colors from '../css/colors.scss';

/**
 * COMPONENTS
 */

const OutlineButton = ({ buttonProps, children, duration, disabled }) => {
  const { color, className } = buttonProps;
  const hexColor = colors[color];
  return (
    <Button
      {...buttonProps}
      className={`${className} timeout-button-outline`}
      disabled={disabled}
    >
      <div className="timeout-button-content-holder">
        {children}
      </div>
      <div
        className="timeout-button-outline-progress"
        style={{
          animationDuration: `${duration}s`,
          backgroundColor: hexColor,
        }}
      />
    </Button>
  );
};

OutlineButton.propTypes = {
  buttonProps: PropTypes.object.isRequired,
  duration: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
};

const PlainButton = ({ buttonProps, children, duration, disabled }) => {
  const { color, className } = buttonProps;
  const hexColor = colors[color];
  return (
    <Button
      {...buttonProps}
      className={`${className} timeout-button`}
      disabled={disabled}
      style={{
        animationDuration: `${duration}s`,
        background: `linear-gradient(to right,${hexColor} 50%, ${transparentize(0.5, hexColor)} 50%)`,
      }}
    >
      {children}
    </Button>
  );
};

PlainButton.propTypes = {
  buttonProps: PropTypes.object.isRequired,
  duration: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
};

/**
 * CORE
 */

class TimeoutButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticks: props.ticks,
      max: props.ticks,
    };

    this.onTick = this.onTick.bind(this);
  }

  onTick() {
    const { ticks } = this.state;
    const next = ticks - 1;
    this.setState({ ticks: next });
    if (next <= 0) {
      this.props.onTimeout();
    }
  }

  render() {
    const { ticks, max } = this.state;
    const { buttonProps, disabled } = this.props;
    return (
      <>
        <ReactInterval
          key="timeout-interval"
          enabled={ticks > 0 && !disabled}
          callback={this.onTick}
        />
        {buttonProps.outline ? (
          <OutlineButton
            buttonProps={buttonProps}
            duration={max}
            disabled={disabled}
          >
            {this.props.children}
          </OutlineButton>
        ) : (
          <PlainButton
            buttonProps={buttonProps}
            duration={max}
            disabled={disabled}
          >
            {this.props.children}
          </PlainButton>
        )}
      </>
    );
  }
}

TimeoutButton.propTypes = {
  buttonProps: PropTypes.object,
  ticks: PropTypes.number.isRequired,
  onTimeout: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

TimeoutButton.defaultProps = {
  buttonProps: {
    className: '',
    color: 'primary',
    outline: false,
  },
  disabled: false,
};

export default TimeoutButton;
